*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100%;
    font-family: 'Poppins';
    color: white;
    overflow-x: hidden;
    position: relative;
    z-index: -1;
    scroll-behavior: smooth;
}

h1 {
    font-size: clamp(3rem, 8.7vw, 11rem);
    line-height: 100%;
}

h2 {
    font-size: clamp(1.5rem, 2.6vw, 5rem);
    font-weight: normal;
}

p, li, a {
    font-size: clamp(1rem, 1.4vw, 3rem);
    text-decoration: none;
    list-style: none;
    color: white;
}

:root {
    --dark-blue: #000D21;
    --light-blue: #1762D0;
    --main-blue: #031837;
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    z-index: -10;
}

.btn {
    background-color: var(--light-blue);
    padding: 0.5rem 2rem;
    border-radius: 10px;
}

.btn:hover {
    background-color: var(--dark-blue);
    transition: all 0.1s ease-in;
}

.social-icons:hover {
    filter: invert(28%) sepia(100%) saturate(6713%) hue-rotate(211deg) brightness(91%) contrast(82%);
    transition: all 0.1s ease-in;
}

.loading {
    min-height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-blue);
    z-index: 10;
}

.loading-text {
    position: relative;
    color: var(--main-blue);
    -webkit-text-stroke: 0.3vw var(--light-blue);
}

.loading-text::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-image: -webkit-linear-gradient(60deg, #AD00FF, #0094FF, #24FF00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0 var(--light-blue);
    border-right: 2px solid var(--light-blue);
    overflow: hidden;
    animation: loadingAnimation 2s linear infinite;
}

@keyframes loadingAnimation {
    0%, 10%, 100% {
        width: 0;
    }
    70%, 90% {
        width: 100%;
    }
}


/*******************/
/* LANDING SECTION */
/*******************/

.hero-container {
    min-height: 100vh;
    position: relative;
}


/* Navigation */

.nav-container {
    padding: 1vw 4vw;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.logo-text {
    font-weight: 700;
}

.nav-items {
    position: relative;
}

.nav-hover::before{
    content: ' ';
    position: absolute;
    bottom: 0;
    background: linear-gradient(60deg, #AD00FF, #0094FF, #24FF00);
    width: 100%;
    height: 3px;
    border-radius: 10px;
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: all 0.25s ease-out
}

.nav-hover:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
}
/* Hero Section */

.hero-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80vh;
    padding: 0 4vw;
}

.hero-text {
    transform: translateX(0)
}

.hero-main-text {
    background-image: -webkit-linear-gradient(60deg, #AD00FF, #0094FF, #24FF00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-second-text {
    margin-bottom: 2rem;
}

.hero-socials {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.hero-socials img {
    width: 3.5vw;
}
.svg {
    position: absolute;
    bottom: -2%;
    z-index: -1;
}

/* About Me */

.about-text {
    transform: translateX(2000px);
    width: 50vw;
    padding: 0 0;
}

.about-text p {
    margin-bottom: 1.2rem;
}

.about-text h2 {
    font-weight: 700;
}

.display-none {
    display: none;
}

/****************/
/* WORK SECTION */
/****************/

.work-section {
    min-height: 100vh;
    background-color: var(--dark-blue);
    padding: 0 4vw;
    position: relative;
    z-index: -1;
}

.work-section::before {
    content: 'My Work.';
    font-size: 20vw;
    font-weight: 700;
    width: 100%;
    color: var(--main-blue);
    position: absolute;
    left: -5%;
    z-index: -1;
}

.work-one-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 4rem;
}

.work-container {
    width: 80%;
    position: relative;
}

.work-container p {
    margin-bottom: 2rem;
}

.work-container h2 {
    font-weight: 700;
}

.work-text-wrapper {
    background-color: var(--main-blue);
    max-width: 80%;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid var(--dark-blue);
    box-shadow: 0 0 5px #000000;
}

.leader-laptop {
    position: absolute;
    top: -25%;
    right: 0;
    width: 45vw;
}

.leader-phone {
    position: absolute;
    top: 15%;
    right: 30%;
    width: 10vw;
}

.text-align-right {
    text-align: right;
}

.work-two-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 40vh;
}

.frontline-laptop {
    position: absolute;
    top: -25%;
    left: -20%;
    width: 45vw;
}

.frontline-phone {
    position: absolute;
    top: 15%;
    left: 15%;
    width: 10vw;
}

.spacing {
    padding-bottom: 30rem;
}

.svg-two {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/****************/
/* TALK SECTION */
/****************/

.talk {
    min-height: 110vh;
    background-color: var(--main-blue);
    padding: 0 4vw;
}

.talk-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;

}

.talk-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 50vh;
    background-color: var(--dark-blue);
    padding: 7rem;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--main-blue);
    box-shadow: 0 0 5px #000000;
}

.let-talk {
    font-weight: 700;
    font-size: clamp(3.5rem, 4.5vw, 8rem);
}

.talk-contact {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contact-text {
    font-size: clamp(1rem, 1.8vw, 7rem);
}

.talk-contact-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.contact-social-icons {
    display: flex;
    gap: 2rem;
    position: absolute;
    bottom: 2%;
    right: 2%;
}

.contact-text:hover {
    color:var(--light-blue);
    transition: all 0.1s ease;
}

.arrow-container {
    position: absolute;
    bottom: 5%;
    left: 5%;
    transform: translate(-50%, -50%);
}

.arrow {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    transform: rotate(-135deg);
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    margin: -5px;
    animation: animate 2s infinite
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(1) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(-135deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-135deg) translate(20px, 20px);
    }
}


/***********************/
/* MEDIA QUERY SECTION */
/***********************/

@media (max-width: 576px) {
    /* HERO SECTION */
    .hero-socials {
        display: none;
    }

    .nav-container {
        padding: 0;
    }

    .navbar {
        padding: 2vw 4vw;
    }

    .toggle {
        position: relative;
        width: 32px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1000;
    }

    .toggle::before {
        content: ' ';
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 20px;
        background: white;
        transform: translateY(-10px);
        transition: 0.25s;
        box-shadow: 0 10px 0 white;
    }

    .toggle.active::before {
        transform: translateY(0) rotate(45deg);
        box-shadow: 0 0 0 white;
    }

    .toggle::after {
        content: ' ';
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 20px;
        background: white;
        transform: translateY(10px);
        transition: 0.25s;
    }

    .toggle.active::after {
        transform: translateY(0) rotate(-45deg);
    }

    .nav-list {
        flex-direction: column;
        background-color: var(--dark-blue);
        position: absolute;
        top: -1000px;
        left: 0;
        width: 100%;
        padding: 4rem 0;
        gap: 3rem;
        z-index: 10;
        transition: all 0.5s ease;
    }

    .nav-list-toggle {
        top: 0;
        transition: all 0.5s ease;
    }

    .nav-list a {
        font-size: 1.2rem;
    }

    .hero-text-container {
        position: relative;
    }

    .about-text {
        position: absolute;
        top: 20%;
        left: 50%;
    }

    .svg{
        bottom: 0;
    }

    /* WORK SECTION */
    .work-one-wrapper {
        min-height: 100vh;
    }

    .work-text-wrapper {
        max-width: 60%;
        min-height: 36vh;
        margin: auto;
        text-align: center;
    }

    .leader-laptop {
        width:100%;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .leader-phone {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 25%;
    }

    .work-two-wrapper {
        justify-content: center;
    }

    .frontline-laptop {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
    }

    .frontline-phone {
        top: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 25%;
    }

    /* TALK SECTION */
    .talk {
        min-height: 50vh;
        padding: 0 0;
    }

    .talk-wrapper {
        flex-direction: column;
        justify-content: center;
        padding: 0;
        min-height: 70vh;
        gap: 3rem;
    }

    .talk-contact-wrapper {
        flex-direction: column;
        justify-content: center;
        gap: 2px;
    }

    .talk-contact-wrapper a {
        font-size: 1.2rem;
    }

    .talk-contact-wrapper img {
        width: 40px;
    }

    .contact-social-icons {
        position: relative;
        justify-content: center;
        align-items: center;
    }

    .contact-social-icons img {
        width: 50px;
    }

    .arrow-container {
        left: 90%;
    }
}